import extractCta from '../Cta';
import extractShortDescription from '../ShortDescription';

const transformCtaSection = (item: any) => {
  return {
    __typename: 'CtaSection',
    key: item.id,
    title: item.title,
    sectionCategory: null,
    shortDescription: extractShortDescription(item),
    image: item.image,
    cta: extractCta(item),
    orientation: item?.orientation?.toLowerCase(),
  };
};

export default transformCtaSection;
