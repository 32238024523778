import React from 'react';
import { graphql } from 'gatsby';
//
import { ProductPage } from 'arvesta-ui-library';
import ProductCardProps from 'arvesta-ui-library/build/components/Cards/ProductCard/types';
import { RichTextProps } from 'arvesta-ui-library/build/components/RichText';
import { ContentfulProduct, ContentfulSettings, ContentfulCertification } from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription from '../transforms/ShortDescription';
import extractFileInforamtion from '../transforms/File';
import transformCertification from '../transforms/Certification';
import extractCta from '../transforms/Cta';
import transformCtaSection from '../transforms/BasicPage/CtaSection';

type ProductQueryType = {
  contentfulProduct: ContentfulProduct;
  contentfulCookieBanner: any;
  site: any;
  contentfulSettings: ContentfulSettings;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type ProductProps = {
  data: ProductQueryType;
  pageContext: PageContextType;
};

const checkAttributesData = (attributes: any): RichTextProps | null => {
  if (!attributes || !attributes.json) {
    return null;
  }

  const hasData = attributes.json.content.reduce((acc: boolean, item: any) => {
    const subCheck = item.content.reduce((subacc: any, subItem: any) => {
      if (subItem.value && subItem.value.length > 0) {
        return true;
      }

      if (subItem.nodeType === 'hyperlink') {
        return true;
      }

      if (subItem.nodeType === 'asset-hyperlink') {
        return true;
      }

      if (subItem.nodeType === 'entry-hyperlink') {
        return true;
      }

      if (subItem.content && subItem.content[0].content[0].value.length > 0) {
        return true;
      }

      return subacc;
    }, false);

    if (subCheck) {
      return true;
    }

    return acc;
  }, false);

  return hasData ? attributes.json : null;
};

const Product = ({ data, pageContext }: ProductProps) => {
  const {
    title,
    image,
    shortDescription,
    contentsSection,
    attributes,
    document,
    additionalDocuments = [],
    certification,

    relatedProducts,
    relatedProductsCta,
    updatedAt,
    ctaModule,
  } = data.contentfulProduct;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="product"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulProduct.slug ? data.contentfulProduct.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulProduct.metaTags}
      cookieBannerTitle={data.contentfulCookieBanner.title}
      cookieBannerDescription={data.contentfulCookieBanner.description}
      updatedAt={updatedAt}
      ogPageType="article"
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
    >
      <ProductPage
        title={title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        image={image || null}
        contentsSection={{ data: contentsSection ? contentsSection.json : null }}
        attributes={
          checkAttributesData(attributes) ? ({ data: checkAttributesData(attributes) } as RichTextProps) : undefined
        }
        document={extractFileInforamtion(document)}
        additionalDocuments={
          additionalDocuments && Array.isArray(additionalDocuments)
            ? additionalDocuments.map(doc => extractFileInforamtion(doc))
            : []
        }
        certification={transformCertification(certification as Array<ContentfulCertification>)}
        relatedProducts={{
          shortDescription: null,
          cta: extractCta({ cta: relatedProductsCta }),
          products: relatedProducts
            ? (relatedProducts
                .map(relatedProduct => {
                  if (!relatedProduct) {
                    return null;
                  }
                  return {
                    id: relatedProduct.id,
                    title: relatedProduct.title,
                    price: relatedProduct.price,
                    shortDescription: extractShortDescription(relatedProduct as any),
                    image: relatedProduct.image,
                    link: relatedProduct.slug,
                    showLink: true,
                    variant: 'vertical',
                  };
                })
                .filter(Boolean) as ProductCardProps[])
            : [],
        }}
        ctaModule={ctaModule ? { ...transformCtaSection(ctaModule) } : null}
      />
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(node_locale: { eq: $node_locale }) {
      title
      description {
        json
      }
    }
    contentfulProduct(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      shortDescription {
        text: shortDescription
      }
      category {
        slug
        title
      }
      price
      image {
        title
        altText
        asset {
          description
          fluid(quality: 85) {
            ...QueryImageFluid
          }
        }
        socialImage: asset {
          fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
            ...QueryImageFixed
          }
        }
      }
      contentsSection {
        json
      }
      attributes {
        json
      }
      document {
        id
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      additionalDocuments {
        id
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      certification {
        title
        asset {
          description
          fixed(width: 112, height: 112, resizingBehavior: FILL, quality: 85) {
            ...QueryImageFixed
          }
        }
        url
      }
      relatedProducts {
        id
        slug
        title
        shortDescription {
          text: shortDescription
        }
        image {
          title
          altText
          asset {
            description
            fluid(quality: 85) {
              ...QueryImageFluid
            }
          }
        }
      }
      relatedProductsCta {
        externalUrl
        internalEntry {
          ...FragmentNavigationLink
        }
        title
      }
      ctaModule {
        ...QueryCtaSection
      }
    }
    contentfulSettings(node_locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }
  }
`;
